import React from "react";
import { Container, Card } from "react-bootstrap";
import { Element } from "react-scroll";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { Link } from 'react-scroll';

const Home: React.FC = () => {
  return (
    <Element id='home' name="home">
      <Container className="text-center d-flex align-items-center justify-content-center vh-100" id='home'>
        <Card className="blur-12 shadow-lg text-white bg-transparent border-black">
          <Card.Body>
            <Card.Title className="display-4">Full-Stack Software Developer</Card.Title>
            <Card.Text className="fs-6">
            Resolving design challenges and creating smart user interfaces. 
            My passion is seamlessly integrating these elements to build compelling web applications and enhance user experiences. 
            From frontend design to backend functionality, I'm dedicated to delivering polished solutions that balance form and function. 
            </Card.Text>
            <Link to="about" spy={true} smooth={true} offset={-70} duration={500} className="styled-link fs-2 text-decoration-underline">
              About Me
              <FontAwesomeIcon icon={faArrowRight} className="arrow-icon" />
            </Link>
          </Card.Body>
        </Card>
      </Container>
    </Element>
  );
}

export default Home;
