import React from "react";
import { Container, Card } from "react-bootstrap";
import { Element } from "react-scroll";

const About: React.FC = () => {
  return (
    // d-flex align-items-center justify-content-center vh-100
    <Element id='about' name="about">
      <Container className="py-5 text-center d-flex align-items-center justify-content-center vh-100" id='about'>
        <Card className="blur-12 shadow-lg text-white bg-transparent border-black" bg="dark" text='light'>
          <Card.Body className="d-flex align-items-center flex-column flex-md-row">
            <Card.Img src="/avat.jpg" className="rounded-circle border border-dark border-2 img-fluid me-1" style={{ maxWidth: "400px" }} />
            <div className="text-center text-md-left">
              <Card.Title className="display-4">Hi, I'm Przemek</Card.Title>
              <Card.Subtitle className="mb-2 text-white-50 fs-4">Full-stack Developer</Card.Subtitle>
              <Card.Text className="fs-6">
              Passionate full-stack developer, dedicated problem solver and highly organized individual with unwavering loyalty as an employee and meticulous attention to detail. 
              Enthusiastic about coding, video games, and the entire spectrum of frontend and backend technologies. 
              Thrives on working collaboratively on ambitious projects with like-minded and interesting individuals in the ever-evolving world of web development.
              </Card.Text>
            </div>
          </Card.Body>
        </Card>
      </Container>
    </Element>
  );
}

export default About;
