import React, { useState, useEffect, useMemo, useCallback } from 'react';
import './App.css';
import Header from './components/header';
import Home from './pages/home';
import About from './pages/about';
import Skills from './pages/skills';
import Portfolio from './pages/portfolio';
import Contact from './pages/contact';
import ParticleBackground from './components/particleBackground';

function App() {
  const [currentSectionIndex, setCurrentSectionIndex] = useState(-1);

  const sections = useMemo(() => ['home', 'about', 'skills', 'portfolio', 'contact'], []);

  const handleWheel = useCallback(
    (e: any) => {
      if (e.deltaY > 0 && currentSectionIndex + 1 < sections.length) {
        setCurrentSectionIndex(currentSectionIndex + 1);
      } else if (e.deltaY < 0 && currentSectionIndex - 1 >= 0) {
        setCurrentSectionIndex(currentSectionIndex - 1);
      }
    },
    [currentSectionIndex, sections.length]
  );

  useEffect(() => {
    const element = document.getElementById(sections[currentSectionIndex]);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }

    const handleKeyDown = (e: any) => {
      if (e.key === 'ArrowDown' && currentSectionIndex + 1 < sections.length) {
        setCurrentSectionIndex(currentSectionIndex + 1);
      } else if (e.key === 'ArrowUp' && currentSectionIndex - 1 >= 0) {
        setCurrentSectionIndex(currentSectionIndex - 1);
      }
    };

    window.addEventListener('wheel', handleWheel);
    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('wheel', handleWheel);
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [currentSectionIndex, sections, sections.length, handleWheel]);


  return (
    <>
      <div id="app-container" className="app-container">
        <Header activeSection={sections[currentSectionIndex]} />
        <div className="App">
            <ParticleBackground/>
            <Home />
            <About />
            <Skills />
            <Portfolio />
            <Contact />
        </div>
      </div>
    </>
  );
}

export default App;
