import React, { useEffect, useState } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import { Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { firestore } from '../firebase';
import { collection, getDocs } from 'firebase/firestore';

interface Project {
  title: string;
  description: string;
  techStack: string;
  link: string;
  imageUrl: string;
}

const Projects: React.FC = () => {
  const [data, setData] = useState<Project[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const projectsCollection = collection(firestore, 'projects');
        const snapshot = await getDocs(projectsCollection);
        const items = snapshot.docs.map((doc) => doc.data() as Project);
        setData(items);
      } catch (error) {
        setError(error as Error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []); // Run once when the component mounts

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>Error: {error.message}</p>;
  }

  return (
    <Carousel style={{ maxWidth: '1000px', margin: 'auto' }} fade className='bg-black text-white border rounded-5'>
      {data.map((project, index) => (
        <Carousel.Item key={index}>
          <div className="d-flex flex-column align-items-center justify-content-center">
            <img
              className="d-block rounded-top-5 img-fluid"
              src={project.imageUrl}
              alt={`Project ${index + 1}`}
              style={{maxHeight: "40vh"}}
            />
            <Card className="blur-12 bg-transparent border-2 text-white text-center mt-3 mb-5">
              <Card.Body>
                <Card.Title>{project.title}</Card.Title>
                <Card.Text className='fs-6'>{project.description}</Card.Text>
                <Card.Text className='fw-bold'>
                  Built with: <br />
                  <p className='fw-normal'>{project.techStack}</p>
                </Card.Text>
                <Card.Text className='styled-link fs-5' as='a' target='_blank' href={project.link}>
                  View the code <FontAwesomeIcon icon={faArrowRight} className="arrow-icon" />
                </Card.Text>
              </Card.Body>
            </Card>
          </div>
        </Carousel.Item>
      ))}
    </Carousel>
  );
};

export default Projects;
