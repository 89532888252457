import React from "react";
import { Container, Card } from "react-bootstrap";
import { Element } from "react-scroll";

const Contact: React.FC = () => {
  return (
    <Element id='contact' name='contact'>
      <Container className="py-5 text-center d-flex align-items-center justify-content-center vh-100" id='contact'>
        <Card className="blur-12 shadow-lg text-white bg-transparent border-black" bg="dark" text='light'>
          <Card.Body>
            <Card.Title className="display-4 mb-4">Let's Connect!</Card.Title>
            <Card.Text className="fs-5">
              What would you do if you had a software expert available at your fingertips?
              <br />
              Want to start a new project? Or just say hey.
              <br />
              <p className="fs-3 mt-3">
                <a style={{color: '#00a5e3'}} href="mailto:przemek.fied3@proton.me">przemek.fied3@proton.me</a>
              </p>
            </Card.Text>
          </Card.Body>
        </Card>
      </Container>
    </Element>
  );
}

export default Contact;
