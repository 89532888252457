import React, {useState, useEffect} from "react";
import { Container, Card } from "react-bootstrap";
import { Element } from "react-scroll";
import Projects from "../components/projects";

const Portfolio: React.FC = () => {
    const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth <= 767);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 767);
        };

        window.addEventListener("resize", handleResize);

        // Cleanup the event listener when the component unmounts
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    return (
        <Element id='portfolio' name="portfolio">
            <Container className={`py-5 text-center bg-transparent ${isMobile ? '' : 'd-flex align-items-center justify-content-center vh-100'}`} id='portfolio'>
                <Card className="blur-12 shadow-lg text-white bg-transparent border-black" bg="dark" text='light'>
                    <Card.Body>
                        <Card.Title className="display-4 mb-4">Portfolio & Previous Projects</Card.Title>
                        <Card.Text className="fs-6">
                            I have built various different projects.
                            If you want to see more examples of my work than the ones showcased on this site, please contact me!
                        </Card.Text>
                        <Projects />
                    </Card.Body>
                </Card>
            </Container>
        </Element>
    );
}

export default Portfolio;
