import React, { useState } from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { Link } from 'react-scroll';

interface HeaderProps {
  activeSection: string;
}

const Header: React.FC<HeaderProps> = ({ activeSection }) => {
  const [expanded, setExpanded] = useState(false);

  const handleToggle = () => setExpanded(!expanded);

  const handleLinkClick = () => setExpanded(false);

  return (
    <Container className='header-container position-fixed top-0 start-50 translate-middle-x'>
      <Navbar expand="lg" variant="dark" className='header' expanded={expanded}>
        <Container>
          <Navbar.Brand style={{ fontFamily: '"Trebuchet MS", sans-serif;', fontWeight: 'bold' }}>SH1RO.DEV</Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={handleToggle} />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link as={Link} className='cursor-pointer' to="home" spy={true} smooth={true} offset={-70} duration={500} onClick={handleLinkClick} active={activeSection === 'home'}>
                Home
              </Nav.Link>
              <Nav.Link as={Link} className='cursor-pointer' to="about" spy={true} smooth={true} offset={-70} duration={500} onClick={handleLinkClick} active={activeSection === 'about'}>
                About
              </Nav.Link>
              <Nav.Link as={Link} className='cursor-pointer' to="skills" spy={true} smooth={true} offset={-70} duration={500} onClick={handleLinkClick} active={activeSection === 'skills'}>
                Skills
              </Nav.Link>
              <Nav.Link as={Link} className='cursor-pointer' to="portfolio" spy={true} smooth={true} offset={-70} duration={500} onClick={handleLinkClick} active={activeSection === 'portfolio'}>
                Portfolio
              </Nav.Link>
              <Nav.Link as={Link} className='cursor-pointer' to="contact" spy={true} smooth={true} offset={-70} duration={500} onClick={handleLinkClick} active={activeSection === 'contact'}>
                Contact
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </Container>
  );
};

export default Header;
